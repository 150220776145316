import React from "react"
// import "../utils/css/components/Logo.css"

export default () => (
  <div
    className="Logo"
    style={{
      backgroundImage: `url(/images/logo.png)`,
    }}
  />
)
